.header_bg {
    background-image: url('../../assets/images/header-bar.png') !important;
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
    background-repeat: no-repeat !important;
}
.lan_select .ant-select-selector {
    border: 0px !important;
    background: transparent !important;
    color: #fff !important;
}
.lan_select .ant-select-arrow {
    color: #fff !important;
}
.menu_icon {
    font-size: 1.3rem;
    color: white !important;
}
.flag_img {
    width: 40%;
    float: right;
    margin: 10px;
}