.main {
  background-image: url("../../assets/images/main_bg.png");
  background-repeat: no-repeat;
  margin-left: 7% !important;
  padding: 5% !important;
  background-size: calc(100% + 0px) calc(100% + 0px) !important;
}
.main_mobile {
  align-self: center;
  display: none;
  background-image: url("../../assets/images/mobilr_header.png");
  background-repeat: no-repeat;
  margin-left: 7% !important;
  padding: 5% !important;
  background-size: calc(100% + 0px) calc(100% + 0px) !important;
}
.main_mobile_top {
  background-image: url("../../assets/images/mobile_head_top.png");
  background-repeat: no-repeat;
  margin-left: 7% !important;
  padding: 5% !important;
  background-size: calc(90% + 0px) calc(95% + 0px) !important;
}
.main_head_txt {
  font-size: 5rem !important;
  color: white !important;
  font-family: "Inter", sans-serif;
}
.main_img_div {
  justify-content: center !important;
  display: flex !important;
}
.main_img_div > img {
  display: none;
}
.flex_basis {
  flex-basis: 200px;
}

.zoom-container:hover .ser_hid_txt {
  display: block !important;
}

.ser_hid_txt {
  display: none;
}

.ser_img_cent_div {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .arrow_div > img {
    width: 100% !important;
    height: 6vh;
  }
  .mr_5 {
    margin: 3% 0 !important;
  }
  .main_mobile {
    display: block;
  }
  .order_1 {
    order: 1;
  }
  .order_2 {
    order: 2;
  }
  .order_3 {
    order: 3;
  }
  .order_4 {
    order: 4;
  }
  .flex_basis {
    flex-basis: 200px;
  }
  .main {
    display: none;
  }
  .main_img_div {
    display: flex; /* Change display to block to stack columns */
    text-align: center; /* Center align the image */
  }

  .main_img_div > img {
    display: block;
    width: 100%; /* Set the width to 100% to make the image responsive */
    margin-bottom: 20px; /* Add some bottom margin to separate image from text */
  }
}
.main_img_div img {
  width: 58%;
}
.dis_flx {
  display: flex;
}
.main span {
  font-size: 2.4rem !important;
  color: white !important;
  font-family: "Inter", sans-serif;
}
.read_btn {
  background-color: #09b0e5 !important;
  color: #fff !important;
  border: 0.1rem solid #09b0e5 !important;
}
.read_btn span {
  font-size: 1.5rem !important;
  font-family: "Inter", sans-serif;
}
.head {
  background-image: url("../../assets/images/head/header.png");
  background-repeat: no-repeat;
  /* margin-left: 7% !important; */
  background-size: calc(100% + 0px) calc(80% + 0px) !important;
  padding: 0 10% !important;
}
.width_66 {
  width: 66%;
}
.head_txt {
  font-size: 2.3rem;
  color: black;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.txt_center {
  display: flex;
  justify-content: center;
  /* text-align: center !important; */
}
.com_txt_div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.com_txt_div span {
  font-size: 2.6rem;
  color: #7c7c7c;
  font-family: "Inter", sans-serif;
}
.com_txt_div > span > span {
  padding-left: 20%;
}
.head_1 {
  background-image: url("../../assets/images/header_1.png");
  background-size: calc(100% + 0px) calc(100% + 0px) !important;
  background-repeat: no-repeat;
}
.head_1_txt {
  font-size: 2.8rem;
  color: #ffffff;
  font-style: italic;
  font-family: "Inter", sans-serif;
}
.ligt_blue_txt {
  color: #02e9ff;
  font-family: "Inter", sans-serif;
}
.head_1_txt_2 {
  font-size: 5rem;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.head_1_txt_3 {
  font-size: 2.4rem;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.pad_10 {
  padding: 10%;
}
.head_1_btm_btn_div {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  height: 100px;
}
.font {
  font-size: 3.4rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.ser_txt {
  text-align: center;
  font-size: 4.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.ser_row {
  padding: 0 10% !important;
}

/* .ser_img {
    width: 60%;
} */
.zoom-container {
  overflow: hidden;
}


.cursor_click {
  cursor: pointer;
}

.ser_img {
  transition: transform 0.3s ease;
  transform-origin: center center;
  width: 80%;
}
.ser_img_div {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  padding: 2rem
}
.ser_col {
  padding: 4.2rem;
}
.zoom-container:hover .ser_img {
  transform: scale(1.1);
}
.ser_main_txt {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.ser_span_txt {
  font-size: 2rem;
  color: #7c7c7c;
  font-family: "Inter", sans-serif;
}
.ser_img_div {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}
.head_2 {
  background-image: url("../../assets/images/header_2.png");
  background-repeat: no-repeat;
  background-size: calc(100% + 0px) calc(100% + 0px) !important;
}
.head_2_main_txt {
  text-align: center;
  color: white;
  font-size: 4.6rem;
  font-family: "Inter", sans-serif;
}
.head_2_sub_txt {
  text-align: center;
  color: white;
  font-size: 2.4rem;
  font-family: "Inter", sans-serif;
}
.center_div {
  padding: 0 7%;
}
.cont_centr {
  justify-content: center !important;
}
.center_txt_div {
  display: flex !important;
  justify-content: center !important;
}
.sol_main_txt {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.sol_sup_txt {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.sol_blue {
  color: #00d5ff !important;
  font-family: "Inter", sans-serif;
}
.pt-5 {
  padding-top: 5% !important;
}
.mr_5 {
  margin: 10% 0;
}
.top_clnt_txt {
  font-size: 4.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.top_sub_txt {
  font-size: 2.4rem;
  font-weight: 500;
  color: #7c7c7c;
  font-family: "Inter", sans-serif;
}
.arrow_div {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
.width_80 {
  width: 80%;
}
.arrow_div img {
  width: 25%;
  height: 8vh;
}
.client_div {
  display: flex !important;
  overflow-x: scroll !important;
  flex-wrap: nowrap !important;
}
.client__col_div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.client__col_div img {
  width: 50%;
}
.client_main_text {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  font-family: "Inter", sans-serif;
}
.client_sub_txt {
  color: #afafaf;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.pb_8 {
  padding-bottom: 8%;
}
.head_3 {
  background-color: #ededed;
}
.pro_main_txt {
  font-size: 4.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.pro_sub_txt {
  color: #7c7c7c;
  font-size: 2.4rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.width_100 {
  width: 100%;
}
.width_50 {
  width: 50%;
}

.pro_gry_txt {
  color: #afafaf;
  font-size: 2rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.pro_blk_txt {
  font-size: 30px;
  font-weight: 600;
}
.skill_bg {
  background-image: url("../../assets/images/skill_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.skill_main_txt {
  font-size: 2.6rem;
  font-weight: 500;
  color: white;
  font-family: "Inter", sans-serif;
}
.skill_sub_txt {
  font-size: 4.6rem;
  font-weight: 500;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.width_65 {
  width: 65%;
}
.skill_pad {
  padding: 7% 20%;
}
.carr_bg {
  background-image: url("../../assets/images/car_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90vh;
  padding: 3%;
}
.carr_txt_div {
  width: 73%;
}
.carr_txt_div span {
  color: #fff;
  font-size: 2rem;
  font-family: "Inter", sans-serif;
}
.hover_span:hover {
  display: none;
}
.hover_span:hover + .hidd_txt {
  display: inline;
}
.hidd_txt {
  display: none;
}
.apply_btn {
  background: #02e9ff !important;
  color: #fff !important;
  font-size: 24px !important;
  height: 6vh !important;
  border: 1px solid #02e9ff !important;
  color: #fff !important;
}
.com_img {
  width: 55%;
}
.img > img {
  width: 95%;
}
.img1 > h2 {
  font-size: 2.2rem;
  font-family: "Inter", sans-serif;
}
.img1 > span {
  font-size: 1.6rem;
  color: #afafaf;
  font-family: "Inter", sans-serif;
}
.nav_div {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;
}
.nav_div img {
  width: 25%;
}
.dir {
  width: 35% !important;
}
.loc_div {
  box-shadow: 0px 4px 8px #f0f0f0 !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
}
.cont_bg {
  background-image: url("../../assets/images/contact_bg.png");
  height: 48vh;
  background-repeat: no-repeat;
  padding: 4% 10%;
  background-size: cover;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 25px;
}
.txt_input {
  height: 54px !important;
  border-radius: 8px !important;
}
.txt_area {
  border-bottom-right-radius: 100px !important;
  border-radius: 8px;
  /* resize: 'none' !important */
}
.footer_bg {
  background-image: url("../../assets/images/footer.png");
  background-repeat: no-repeat;
  background-color: #253646;
  background-size: cover;
}
.snd_btn {
  background: #02e9ff !important;
  height: 9vh !important;
  padding: 10px 25px !important;
  color: white !important;
  border: 1px solid #02e9ff !important;
  font-size: 2.4rem !important;
  font-weight: 600 !important;
  font-family: "Inter", sans-serif;
}
.footer_txt_14 {
  color: #afafaf;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.footer_txt_16 {
  color: #afafaf;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 3% 0;
  font-family: "Inter", sans-serif;
}
.footer_txt_1 {
  color: #f1f1f1 !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif;
}
/*  */
.send_btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.fot_logo {
  display: flex !important;
  flex-direction: column;
  padding: 20% 20% 0 20%;
}
.fot_logo > img {
  padding-bottom: 15%;
}
.fot_logo > span:first-child {
  padding-bottom: 15px;
}
.socl_icn {
  display: flex;
  justify-content: space-evenly;
  padding: 10% 10%;
}
.footer_txt {
  color: #afafaf;
  font-size: 1rem;
  font-weight: 500;
  padding: 0% 0% 10% 15%;
  font-family: "Inter", sans-serif;
}
.fty_link {
  display: flex !important;
  flex-direction: column;
  padding: 4% 0% 0% 10%;
}

.ant-divider-vertical {
  position: relative !important;
  top: 8rem !important;
  display: inline-block;
  height: 4.9rem !important;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid #d9d9d9 !important;
}
.ant-divider .msg_divid {
  position: relative !important;
  top: 18rem !important;
  display: inline-block;
  /* height: 4.9rem !important; */
  margin: 10px 8px !important;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid #ee0000 !important;
}
.s {
  padding: 4% 0% 0% 3%;
}
.ftr_img {
  padding: 5% 5%;
}
.mtb-10 {
  margin: 10px 0 !important;
}
.plf_10 {
  padding: 5% 11%;
  display: flex !important;
}
.ss {
  position: relative;
  width: 100%;
  top: 25vh;
}
.sss {
  row-gap: 0px;
  padding: 10% 5%;
}
.ani_div {
  display: flex;
  justify-content: center;
  height: 130px;
}

.ani_icon {
  flex-basis: 140px;
  background-color: #fff;
  border-radius: 50%;
  /* width: 55%; */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 15px;
}
.map {
  overflow: scroll;
  /* height: auto; */
  width: -webkit-fill-available;
  padding: 0px 10px;
}
