.main_head_torus {
    background-image: url('../../assets/images/torus/heade.png') ;
    background-size: cover;
    background-repeat: no-repeat;
}
.fix_head_txt {
    color: #fff;
    font-size: 92px;
    font-weight: 800;
}
.head_cay_txt {
    color: #00D5FF
}
.head_sub_txt {
    font-size: 26px;
    font-weight: 500;
    color: #fff
}
.head_brd_txt {
    color: #00D5FF;
    font-size: 16px;
    font-weight: 500;
}
.brd_row {
    margin-top: 4%;
    padding-bottom: 15px;
}
.net_txt_col {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}
.net_img {
    width: 80%;
}
.net_main_txt {
    font-size: 30px;
    font-weight: 600;
}
.net_sub_txt {
    color: #7C7C7C;
    font-size: 20px;
    font-weight: 400;
}
.txt_center {
    text-align: center;
}
.ass_net_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.ass_net_div p {
    width: 46%;
    text-align: center;
    color: #7C7C7C;
    font-size: 20px;
    font-weight: 400;
}
.ass_net_div > p > img {
    width: 4%;
}
.icon_width {
    width: 4%;
}