.main_head_we {
    background-image: url('../../assets/images/we_are/header.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.fix_head_txt {
    color: #fff;
    font-size: 92px;
    font-weight: 800;
}
.head_cay_txt {
    color: #00D5FF
}
.head_sub_txt {
    font-size: 26px;
    font-weight: 500;
    color: #fff
}
.head_brd_txt {
    color: #00D5FF;
    font-size: 16px;
    font-weight: 500;
}
.brd_row {
    margin-top: 4%;
    padding-bottom: 15px;
}
.net_txt_col {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}
.net_img {
    width: 80%;
}
.arrow_icon {
    width: 4%;
}
.net_main_txt {
    font-size: 30px;
    font-weight: 600;
}
.full_map_bg {
    width: 100%; /* Set the width as needed */
    height: 100vh; /* Set the height as needed, vh stands for viewport height */
    background-image: url('../../assets/images/we_are/full_map.png');
    background-size: cover;
    background-repeat: no-repeat;
}
/* .full_map_bg {
    background-image: url('../../assets/images/we_are/full_map.png');
    background-size: cover;
    background-repeat: no-repeat;
} */
.txt_center {
    text-align: center !important;
}
.ripple1 {
    position: relative;
    width: 5%;
    top: 14%;
    left: 43%;
}
.ripple2 {
    position: relative;
    width: 5%;
    top: 32%;
    left: 57%;
}
.ripple3 {
    position: relative;
    width: 5%;
    top: 14%;
    left: 60%;
}
.ripple4 {
    position: relative;
    width: 5%;
    top: 2%;
    left: 66%;
}
.add_main_txt {
    font-size: 34px;
    font-weight: 500;
}
.add_sub_txt {
    color: #7C7C7C;
    font-weight: 500;
    font-size: 24px;
}
.add_sub_txt_1 {
    color: #AFAFAF;
    font-size: 18px;
}
.orgn_main_txt {
    font-size: 46px;
}
.orgn_sub_txt {
    color: #7C7C7C;
    font-size: 24px;
    font-weight: 500;
}
.net_sub_txt {
    color: #7C7C7C;
    font-size: 20px;
    font-weight: 400;
}
.txt_center {
    text-align: center;
}
.ass_net_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.ass_net_div p {
    width: 46%;
    text-align: center;
    color: #7C7C7C;
    font-size: 20px;
    font-weight: 400;
}
.ass_net_div > p > img {
    width: 4%;
}