.main_head_client {
    background-image: url("../../assets/images/clients_bg.png");
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
  }
  .main_head_du {
    background-image: url("../../assets/images/our_projects/du_bg.png");
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh;  */
  }
  .main_head_nokia {
    background-image: url("../../assets/images/our_projects/nokia.png");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
  }
  .main_head_eric {
    background-image: url("../../assets/images/our_projects/ericsson_bg.png");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
  }
  .main_head_huaw {
    background-image: url("../../assets/images/our_projects/huawei_bg.png");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
  }
  .main_head_alstom {
    background-image: url("../../assets/images/our_projects/alstom_bg.png");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
  }
  .main_head_solar {
    background-image: url("../../assets/images/our_projects/solor_bg.png");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
  }

  .width_8 {
    width: 8%;
  }

  .main_head_projects {
    background-image: url("../../assets/images/our_projects/projects_bg.png");
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: right;
    /* height: 100vh; */
    background-size: calc(100% + 0px) calc(100% + 0px) !important;
  }
  .main_crr_div {
    padding: 0 5% !important;
  }
  .width_170 {
    width: 170px;
  }
  .mt_5 {
    margin-top: 5%;
  }
  .white_arr_div {
    display: flex !important;
    justify-content: flex-end;
  }
  .white_arr_div img {
    width: 40% !important;
  }
  .fix_head_txt {
    color: #fff;
    font-size: 92px;
    font-weight: 800;
    margin: 0px;
  }
  .arrow_txt {
    font-size: 20px;
    color: #7C7C7C;
  }
  .cent_div_n {
    padding: 0 15%;
  }
  .head_cay_txt {
    color: #00d5ff;
    margin: 0px;
  }
  .icon_div {
    display: flex !important;
    justify-content: flex-end;
  }
  .gry_div {
    background: #fdf9fd;
    display: flex !important;
    align-content: center;
    align-items: center;
    border-radius: 50px;
    height: 40px;
    margin-bottom: 10px;
  }
  .icons {
    width: 25%;
  }
  .cent_div {
    padding: 0 10%;
  }
  .head_sub_txt {
    font-size: 26px;
    font-weight: 500;
    color: #fff;
  }
  .head_brd_txt {
    color: #00d5ff;
    font-size: 16px;
    font-weight: 500;
  }
  .grry_txt {
    color: #7c7c7c;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0 !important;
  }
  .brd_row {
    margin-top: 4%;
    padding-bottom: 15px;
  }
  .net_txt_col {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .net_img {
    width: 80%;
  }
  .arrow_icon {
    width: 4%;
  }
  .net_main_txt {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
  }
  .net_sub_txt {
    color: #7c7c7c;
    font-size: 20px;
    font-weight: 400;
  }
  .txt_center {
    text-align: center;
  }
  .ass_net_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .ass_net_div p {
    width: 46%;
    text-align: center;
    color: #7c7c7c;
    font-size: 20px;
    font-weight: 400;
  }
  .ass_net_div > p > img {
    width: 4%;
  }
  .grey_bg {
    background: #f1f1f1;
  }
  .input {
    border: 1px solid #fff !important;
  }
  .send_btn {
    border: 2px solid #00d5ff !important;
    width: 35% !important;
    font-size: 20px !important;
    padding: 6px !important;
    height: 9vh !important;
    color: #343434 !important;
    border-radius: 5px !important;
  }
  .width_55 {
    width: 55%;
  }
  .txt_div {
    padding-left: 10%;
  }