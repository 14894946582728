/* .menu-page .ant-modal-content {
  background-image: url('../../assets/images/footer_bg.png');
  background-repeat: no-repeat;
} */
.footer_bg_n {
  width: 170%;
  position: relative;
  right: 105px;
}
.eng {
  display: flex !important;
  justify-content: end;
  padding: 0px 20px;
}
.mod_hed {
  padding: 0 10% 5% 10% !important;
}
.mod_hed > img {
  width: 15% !important;
}
.ant-modal-header {
  border-bottom: 1px solid #ffffff !important;
}
.ul_style {
  list-style-type: none;
}
.anc_tag {
  color: #505050 !important;
  font-size: 32px !important;
  font-weight: 700;
}
.ul_style_li {
  padding: 7px 0px;
}
.ul_style_li_2 {
    padding: 7px 0px;
  }
.our_ser {
  color: #343434 !important;
  font-size: 20px !important;
  font-weight: 600;
}
.our_ser_sub {
  color: #7c7c7c !important;
  font-size: 18px !important;
  font-weight: 500;
}
.plr_8{
    padding: 0 5%;
}