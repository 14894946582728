body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaf0f7 !important;
}

#root {
  background: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  font-size: 62.5%; /* Set the root font size */
}
@font-face {
  font-family: "open_sansregular";
  src: url("assets/fonts/open-sans/OpenSans-Regular-webfont.eot");
  src: url("assets/fonts/open-sans/OpenSans-Regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/open-sans/OpenSans-Regular-webfont.woff") format("woff"),
    url("assets/fonts/open-sans/OpenSans-Regular-webfont.ttf")
      format("truetype"),
    url("assets/fonts/open-sans/OpenSans-Regular-webfont.svg#open_sansregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "open_sansbold";
  src: url("assets/fonts/open-sans/OpenSans-Bold-webfont.eot");
  src: url("assets/fonts/open-sans/OpenSans-Bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/open-sans/OpenSans-Bold-webfont.woff") format("woff"),
    url("assets/fonts/open-sans/OpenSans-Bold-webfont.ttf") format("truetype"),
    url("assets/fonts/open-sans/OpenSans-Bold-webfont.svg#open_sansbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-button {
  background-color: #fff;
  height: 0;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #ccc;
  border-radius: 3px;
}
::-webkit-scrollbar-corner {
  background-color: #ccc;
}
::-webkit-resizer {
  background-color: #ccc;
}
* {
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
@media (max-width: 992px) {
  :root {
    font-size: 45%;
  }
  :root > img {
    height: 45%;
    width:45%;
  }
}
@media (max-width: 768px) {
  :root {
    font-size: 40%;
  }
  /* img {
    width:10%;
  } */
}
@media (max-width: 576px) {
  :root {
    font-size: 35%;
  }
  :root > img {
    height: 35%;
    width:35%;
  }
}
@media (max-width: 480px) {
  :root {
    font-size: 25%;
  }
  :root > img {
    height: 25%;
    width:25%;
  }
  
}
